// Import required modules and CSS
import 'ol/ol.css';
import { Map, View } from 'ol/';
import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import { Image as ImageLayer } from 'ol/layer';
import Projection from 'ol/proj/Projection';
import ImageWMS from 'ol/source/ImageWMS';
import { OSM } from 'ol/source';
import { ScaleLine, defaults as defaultControls } from 'ol/control.js';
import XYZ from 'ol/source/XYZ.js';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';

/*import {
  DragRotateAndZoom,
  defaults as defaultInteractions,
} from 'ol/interaction.js';
*/


// Define the URL for the WMS service
//const adns = "http://ec2-13-233-224-228.ap-south-1.compute.amazonaws.com:8080/geoserver/webapp/wms?";
//const serverurl = "http://localhost:8080/geoserver/mvp/wms?";

// Create a ScaleLine control with specified configurations
let control = new ScaleLine({
  units: 'metric',
  steps: 1,
  bar: true,
  text: true,
  minWidth: 100,
  className: 'scale',
});
// Define OSM layer as the base map
const Osmlayer = new TileLayer({
  source: new OSM(),
  name: 'OSM',
});

// Define the google satellite xyz tile
const googlesatellite = new TileLayer({
  source: new XYZ({
    url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
  }),
  visible: true,
  name: "Google Satellite",
});

// WFS vector layer
const vectorSource = new VectorSource({
  format: new GeoJSON(),
  url: function (extent) {
    return 'https://www.reservemyplot.com:4433/geoserver/landnest/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=landnest%3Aplots&maxFeatures=50&outputFormat=application/json&srsname=EPSG:3857&' +
      'bbox=' + extent.join(',') + ',EPSG:3857';
  },
  strategy: bboxStrategy
});

const vectorLayer = new VectorLayer({
  source: vectorSource,
  visible:true,
  name:"WFS"
});


// Define the map projection
const mapProjection2 = new Projection({
  code: 'EPSG:3857',
  units: 'm',
  axisOrientation: 'neu',
  global: false,
});

// Orthophoto/Raster
const orthosource = new TileWMS({
  url: 'https://reservemyplot.com:4433/geoserver/landnest/wms?service=WMS&version=1.1.0&request=GetMap&layers=landnest%3Aodm_orthophoto&bbox=388356.5238715806%2C1736617.7582758337%2C388754.33394494123%2C1737031.7483640653&width=737&height=768&srs=EPSG%3A32643&styles=&format=application/openlayers',
  params: { "LAYERS": "landnest:odm_orthophoto", "VERSION": "1.1.1", "FORMAT": "image/png" },
});

const ortholayer = new TileLayer({
  source: orthosource,
  name: 'Map',
});

// Plots layer
const plotssrc = new ImageWMS({
  url: 'https://reservemyplot.com:4433/geoserver/landnest/wms?service=WMS&version=1.1.0&request=GetMap&layers=landnest%3Aplots&bbox=388465.1271559606%2C1736691.2978025472%2C388649.3650261992%2C1736865.7495511144&width=768&height=727&srs=EPSG%3A32643&styles=&format=application/openlayers',
  params: { "LAYERS": "landnest:plots", "VERSION": "1.1.1", "FORMAT": "image/png" },
});

const plots = new ImageLayer({
  source: plotssrc,
  visible: true,
  name: "Plots",
});

//locationindicator
const indicatorsrc = new ImageWMS({
  url: 'https://reservemyplot.com:4433/geoserver/landnest/wms?service=WMS&version=1.1.0&request=GetMap&layers=landnest%3Alocation_marker&bbox=8233206.307104497%2C1770784.8905657565%2C8233208.307104497%2C1770786.8905657565&width=768&height=768&srs=EPSG%3A3857&styles=&format=application/openlayers',
  params: { "LAYERS": "landnest:location_marker", "VERSION": "1.1.1", "FORMAT": "image/png" },
});

const locationindicator = new ImageLayer({
  source: indicatorsrc,
  visible: true,
  name:"Indicator",
  
});


// Define the View
// Split the centerpoint string into an array of coordinates
const centerCoordinates = centerpoint.split(',').map(parseFloat);

// Create the view using the center coordinates
const view1 = new View({
    center: centerCoordinates,
    zoom: 17,
    multiWorld: false,
    projection: mapProjection2,
});


// Define the map
// Define the map
const map = new Map({
  controls: defaultControls().extend([control]),
  //interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
  target: "map",
  layers: [googlesatellite,Osmlayer,vectorLayer,  ortholayer, plots, locationindicator], // Google Satellite layer first
  view: view1,
});

// Store the map data for later use
$('#map').data('map', map);

//import OLCesium from 'olcs/OLCesium.js'; // Uncomment this line
// Enable 3D rendering with Cesium
//const ol3d = new OLCesium({ map: map });
//ol3d.setEnabled(true);